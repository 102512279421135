import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import client from "service/client";

export function useCommunicationMessagesList() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.communicationMessage.getCommunicationMessages,
    {
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useMessageDetails() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.communicationMessage.getMessagesDetails,
    {
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          // toast.error(`${data?.email}`);
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useSendMessage() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.communicationMessage.sendMessage,
    {
      onSuccess: (data) => {},
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          // toast.error(`${data?.email}`);
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}
export function useSendReplyMessage() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.communicationMessage.sendMessageReply,
    {
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          // toast.error(`${data?.email}`);
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}
