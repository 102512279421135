import { Eye } from "components/icons/eye-icon";
import Pagination from "components/ui/pagination";
import { Table } from "components/ui/table";
import { Routes } from "config/routes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, Route } from "react-router-dom";

import { PaginatorInfoProps } from "types";
import { CommunicationMessage } from "types/communicationMessage";

type IProps = {
  communicationList: CommunicationMessage[] | undefined;
  paginatorInfo: PaginatorInfoProps | null;
  onPagination: (current: number) => void;
};

const CommunicationMessagesList = ({
  communicationList,
  paginatorInfo,
  onPagination,
}: IProps) => {
  const { t } = useTranslation("common");

  const MessagesTableColumns = useMemo(
    () => [
      {
        title: t("text-message-id"),
        dataIndex: "communication_id",
        key: "communication_id",
        align: "center",
        ellipsis: true,
        className: "!text-sm",
        width: 80,
      },
      {
        title: t("text-title"),
        dataIndex: "title",
        key: "title",
        align: "left",
        ellipsis: true,
        className: "!text-sm",
        width: 210,
      },
      {
        title: t("text-message-create"),
        dataIndex: "_created_at",
        key: "_created_at",
        align: "center",
        className: "!text-sm",
        width: 160,
      },
      {
        title: t("text-unread-message"),
        dataIndex: "unread_count",
        key: "unread_count",
        align: "center",
        className: "!text-sm",
        width: 150,
      },
      {
        title: t("text-details"),
        dataIndex: "action",
        key: "action",
        align: "center",
        className: "!text-sm",
        width: 50,
        render: (value: any, row: CommunicationMessage) => (
          <Link
            to={`/admin${Routes.CRM}${Routes.CRMCustomerMessageDetails}`}
            state={{
              customer_email: row.customer_email,
              communication_id: row.communication_id,
              businessdetail_id: row.businessdetail_id,
            }}
            className="inline-block transition duration-200 text-body hover:text-accent-hover focus:text-accent-hover"
            title={t("text-details")}
          >
            <Eye width={20} />
          </Link>
        ),
      },
    ],
    [t]
  );

  return (
    <>
      <div className="mb-6 overflow-hidden rounded shadow overflow-x-auto">
        <Table
          /* @ts-ignore */
          columns={MessagesTableColumns}
          emptyText={t("table:empty-table-data")}
          data={communicationList}
          rowKey="communication_id"
          scroll={{ x: 900 }}
        />
      </div>
      {!!paginatorInfo?.total && (
        <div className="flex items-center justify-end">
          <Pagination
            total={paginatorInfo.total}
            current={paginatorInfo.currentPage}
            pageSize={paginatorInfo.pageSize}
            onChange={onPagination}
            showLessItems
          />
        </div>
      )}
    </>
  );
};

export default CommunicationMessagesList;
