import { Routes, Route } from "react-router-dom";
import AdminLayout from "../components/layouts/admin";
import AuthorizedRoute from "../components/AuthorizedRoute";
import { Routes as RoutesPath } from "../config/routes";
import ProductManagementRoutes from "./productManagement.routes";
import HomeView from "../views/dashboard";
import BusinessDetailsRoutes from "./businessDetails.routes";
import ProductListingRoutes from "./productListing.routes";
import AiAnalyticsRoutes from "./AIAnalytics.routes";
import AiCopilotRoutes from "./AICopilot.routes";
import BusinessInPersonRoutes from "./businessInPerson.routes";
import CRMRoutes from "./CRM.routes";

const DashboardRoutes = () => {
  return (
    <AdminLayout>
      <Routes>
        <Route
          path={RoutesPath.dashboard}
          element={
            <AuthorizedRoute userRole={""} route={null}>
              <HomeView />
            </AuthorizedRoute>
          }
        />

        <Route
          path={`${RoutesPath.BusinessSetup}/*`}
          element={<BusinessDetailsRoutes />}
        />

        <Route
          path={`${RoutesPath.ProductManagement}/*`}
          element={<ProductManagementRoutes />}
        />

        <Route
          path={`${RoutesPath.ProductListing}/*`}
          element={<ProductListingRoutes />}
        />

        <Route
          path={`${RoutesPath.AIAnalytics}/*`}
          element={<AiAnalyticsRoutes />}
        />

        <Route
          path={`${RoutesPath.AICopilot}/*`}
          element={<AiCopilotRoutes />}
        />

        <Route
          path={`${RoutesPath.BusinessInPerson}/*`}
          element={<BusinessInPersonRoutes />}
        />
        <Route path={`${RoutesPath.CRM}/*`} element={<CRMRoutes />} />
      </Routes>
    </AdminLayout>
  );
};

export default DashboardRoutes;
