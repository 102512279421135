import { Table } from "../../ui/table";
import { useTranslation } from "react-i18next";
import {
  MappedPaginatorInfo,
  SortOrder,
  BusinessDetailItem,
  PaginatorInfoProps,
} from "../../../types";
import { useState } from "react";
import Pagination from "../../ui/pagination";
import { TrashIcon } from "../../icons/trash";
import { useModalAction } from "../../../provider/modal.context";
import { Eye } from "../../icons/eye-icon";
import { FileBusinessStory } from "types/businessInPerson";

export type IProps = {
  filesList: FileBusinessStory[] | undefined;
  paginatorInfo: PaginatorInfoProps | null;
  onPagination: (current: number) => void;
  onDeleteItem: (item: FileBusinessStory) => void;
};

type SortingObjType = {
  sort: SortOrder;
  column: string | null;
};

const BusinessFilesList = ({
  filesList,
  paginatorInfo,
  onPagination,
  onDeleteItem,
}: IProps) => {
  const { t } = useTranslation();
  const { openModal } = useModalAction();

  const alignLeft = "left";
  const alignRight = "right";

  const [sortingObj, setSortingObj] = useState<SortingObjType>({
    sort: SortOrder.Desc,
    column: null,
  });

  let columns = [
    {
      title: t("table:table-item-file-name"),
      dataIndex: "file_name",
      key: "file_name",
      width: 150,
      align: "center",
      ellipsis: true,
      render: (name: string) => {
        return (
          <span className="truncate whitespace-nowrap">{name ?? "-"}</span>
        );
      },
    },
    {
      title: t("table:table-item-file-type"),
      dataIndex: "file_name",
      key: "file_name",
      width: 120,
      align: "center",
      ellipsis: true,
      render: (name: string) => {
        return (
          <span className="truncate whitespace-nowrap">
            {name.substring(name.lastIndexOf(".") + 1) ?? "-"}
          </span>
        );
      },
    },
    {
      title: t("table:table-item-action"),
      className: "cursor-pointer",
      dataIndex: "action",
      key: "action",
      align: alignRight,
      width: 50,
      render: function Render(value: number, record: FileBusinessStory) {
        return (
          <div
            className="space-x-4"
            style={{
              display: "flex",
              alignItems: "start",
              justifyContent: "end",
            }}
          >
            <button
              name="preview"
              onClick={() => {
                openModal("ProductManagement_Image_Or_Video_Preview", {
                  item: record.file_urls[0],
                  callback: () => {},
                });
              }}
              className="text-body transition duration-200 hover:text-heading focus:outline-none"
              title={"preview"}
            >
              <Eye width={16} />
            </button>
            <button
              onClick={() => {
                onDeleteItem(record);
              }}
              className="text-red-500 transition duration-200 hover:text-red-600 focus:outline-none"
              title={t("common:text-delete")}
            >
              <TrashIcon width={16} />
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="mb-6 overflow-hidden rounded shadow overflow-x-auto">
        <Table
          /* @ts-ignore */
          columns={columns}
          emptyText={t("table:empty-table-data")}
          data={filesList}
          rowKey="id"
          // scroll={{ x: 900 }}
        />
      </div>

      {!!paginatorInfo?.total && (
        <div className="flex items-center justify-end">
          <Pagination
            total={paginatorInfo.total}
            current={paginatorInfo.currentPage}
            pageSize={paginatorInfo.pageSize}
            onChange={onPagination}
            showLessItems
          />
        </div>
      )}
    </>
  );
};

export default BusinessFilesList;
