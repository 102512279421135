import { Routes, Route } from "react-router-dom";
import { Routes as RoutesPath } from "../config/routes";
import AuthorizedRoute from "../components/AuthorizedRoute";
import InPersonServicesView from "views/business-in-person/in-person-services/InPersonServices";
import BusinessStoryFileView from "views/business-in-person/business-story-file/BusinessStoryFile";

const BusinessInPersonRoutes = () => {
  return (
    <Routes>
      <Route
        path={RoutesPath.BusinessInPersonServices}
        element={
          <AuthorizedRoute userRole={""} route={null}>
            <InPersonServicesView />
          </AuthorizedRoute>
        }
      />
      <Route
        path={RoutesPath.BusinessStoryFile}
        element={
          <AuthorizedRoute userRole={""} route={null}>
            <BusinessStoryFileView />
          </AuthorizedRoute>
        }
      />
    </Routes>
  );
};

export default BusinessInPersonRoutes;
