import React from "react";
import BusinessStoryFileForm from "components/business-in-person-form/business-story-file/BusinessStoryFileForm";
import { useTranslation } from "react-i18next";

export default function BusinessStoryFileView() {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex border-b border-dashed border-border-base py-5 sm:py-8">
        <h1 className="text-lg font-semibold text-heading">
          {`${t("common:sidebar-nav-item-setup-in-person")} / ${t(
            "common:sidebar-nav-sub-item-business-story-file"
          )}`}
        </h1>
      </div>
      <BusinessStoryFileForm />
    </>
  );
}
