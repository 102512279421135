import Button from "components/ui/button";
import Card from "components/ui/cards/card";
import Description from "components/ui/description";
import Input from "components/ui/forms/input";
import { useModalAction } from "provider/modal.context";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFileBusinessStoriesList } from "service/hook/businessStoryFile";
import { userStore } from "store";
import {
  FileBusinessStory,
  FileBusinessStoryResponseModel,
} from "types/businessInPerson";
import { PageSize } from "utils/constants";
import BusinessFilesList from "./BusinessFilesList";

export default function BusinessStoryFileFormView() {
  const { t } = useTranslation();
  const { businessItem } = userStore((state) => state);

  const { openModal } = useModalAction();

  const [fileName, setFileName] = useState<string>("");
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [fileStoriesList, setFileStoriesList] = useState<FileBusinessStory[]>(
    []
  );

  const { mutate: mutateFileBusinessStoriesList, isLoading } =
    useFileBusinessStoriesList();

  useEffect(() => {
    if (businessItem) getFileBusinessStoryList();
  }, [businessItem, page]);

  const getFileBusinessStoryList = (fileName?: string, pageNumber?: number) => {
    mutateFileBusinessStoriesList(
      {
        file_name: fileName,
        businessdetail_id: `${businessItem?.business_id ?? 0}`,
        page: pageNumber ?? page,
        size: PageSize,
      },
      {
        onSuccess: (successData: FileBusinessStoryResponseModel) => {
          if (successData.final_result_code === "000") {
            setFileStoriesList(successData.captured_data);
            setTotalCount(successData.total_count ?? 0);
          }
        },
      }
    );
  };

  const onSubmit = async () => {
    getFileBusinessStoryList(fileName, 1);
  };

  const handleChangePage = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const deleteItem = async (item: any) => {
    openModal("Business_Story_File_Delete", {
      item: { ...item },
      callback: () => {
        page === 1 ? getFileBusinessStoryList() : handleChangePage(1);
      },
    });
  };

  return (
    <>
      <div className="my-5 flex flex-wrap sm:my-8">
        <Description
          details={[t("form:form-description-business-story-file")]}
          className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
        />
        <Card className="w-full sm:w-8/12 md:w-2/3">
          <Input
            label={t("form:input-label-file-name")}
            name="searchKey"
            value={fileName}
            onChange={(e) => {
              setFileName(e.target.value);
            }}
            variant="outline"
            className="mb-5"
            maxLength={50}
          />

          <div
            className="mb-4 text-start"
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <div
              className="mb-4 text-start"
              style={{
                display: "flex",
                justifyContent: "start",
              }}
            >
              <Button
                onClick={() => {
                  onSubmit();
                }}
                loading={isLoading}
                disabled={isLoading}
              >
                {"Search"}
              </Button>
              <Button
                variant={"secondary"}
                onClick={() => {
                  openModal("Business_Story_Add_File", {
                    item: null,
                    callback: () => {
                      page === 1
                        ? getFileBusinessStoryList()
                        : handleChangePage(1);
                    },
                  });
                }}
                className={"ml-4"}
                loading={false}
                disabled={isLoading}
              >
                {"Add new File"}
              </Button>
            </div>
          </div>

          <BusinessFilesList
            filesList={fileStoriesList}
            paginatorInfo={{
              total: totalCount,
              currentPage: page,
              pageSize: PageSize,
            }}
            onPagination={handleChangePage}
            onDeleteItem={(item: FileBusinessStory) => {
              deleteItem(item);
            }}
          />
        </Card>
      </div>
    </>
  );
}
