import React from "react";

type MODAL_VIEWS =
  | "REGISTER"
  | "LOGIN_VIEW"
  | "FORGOT_VIEW"
  | "OTP_LOGIN"
  | "ADD_OR_UPDATE_ADDRESS"
  | "ADD_OR_UPDATE_CHECKOUT_CONTACT"
  | "ADD_OR_UPDATE_PROFILE_CONTACT"
  | "ADD_OR_UPDATE_GUEST_ADDRESS"
  | "LOCATION_BASED_SHOP"
  | "DELETE_ADDRESS"
  | "PRODUCT_DETAILS"
  | "REFUND_REQUEST"
  | "REVIEW_RATING"
  | "QUESTION_FORM"
  | "ABUSE_REPORT"
  | "SHOP_INFO"
  | "SELECT_PRODUCT_VARIATION"
  | "REVIEW_IMAGE_POPOVER"
  | "USE_NEW_PAYMENT"
  | "PAYMENT_MODAL"
  | "STRIPE_ELEMENT_MODAL"
  | "DELETE_CARD_MODAL"
  | "ADD_NEW_CARD"
  | "ADD_NEW_CARD_DURING_PAYMENT"
  | "USE_SAVED_CARD"
  | "GATEWAY_MODAL"
  | "TeamMember_Delete_User"
  | "BranchLocation_Delete_Branch"
  | "BankAccount_Delete_account"
  | "BusinessDocument_Delete_Document"
  | "ProductManagement_Add_Policy"
  | "ProductManagement_Delete_Policy"
  | "ProductManagement_Add_Image"
  | "ProductManagement_Delete_Image"
  | "ProductManagement_Add_Video"
  | "ProductManagement_Delete_Video"
  | "ProductManagement_Image_Or_Video_Preview"
  | "ProductManagement_Inventory_Add_Image_From_ImageList"
  | "ProductManagement_Inventory_Delete"
  | "ProductManagement_Inventory_Add_Video_From_VideoList"
  | "ProductManagement_Inventory_Add_Aspect_From_AspectList"
  | "ProductManagement_Offer_Add_SKU_From_SKUList"
  | "ProductManagement_Offer_Add_Policy_From_PolicyList"
  | "Listing_Un_List_Confirm"
  | "ProductManagement_Inventory_Add_Document_From_DocumentList"
  | "InPersonServicesDetailsModal"
  | "Business_Story_File_Delete"
  | "Business_Story_Add_File"
  | "REPLY_MESSAGE"
  | "BUSINESS_PROFILE_MANAGEMENT_DELETE";

interface State {
  view?: MODAL_VIEWS;
  view2?: MODAL_VIEWS;
  data?: any;
  data2?: any;
  isOpen1: boolean;
  isOpen2: boolean;
}
type Action =
  | { type: "open1"; view?: MODAL_VIEWS; payload?: any }
  | { type: "open2"; view2?: MODAL_VIEWS; payload?: any }
  | { type: "close1" }
  | { type: "close2" }
  | { type: "close" };

const initialState: State = {
  view: undefined,
  view2: undefined,
  isOpen1: false,
  isOpen2: false,
  data: null,
  data2: null,
};

function modalReducer(state: State, action: Action): State {
  switch (action.type) {
    case "open1":
      return {
        ...state,
        view: action.view,
        data: { ...state.data, ...action.payload },
        isOpen1: true,
      };
    case "open2":
      return {
        ...state,
        view2: action.view2,
        data2: { ...state.data2, ...action.payload },
        isOpen2: true,
      };
    case "close1":
      return {
        ...state,
        view: undefined,
        view2: undefined,
        data: null,
        isOpen1: false,
        isOpen2: false,
      };
    case "close2":
      return {
        ...state,
        view2: undefined,
        data2: null,
        isOpen1: true,
        isOpen2: false,
      };
    case "close":
      return {
        ...state,
        view: undefined,
        view2: undefined,
        data: null,
        data2: null,
        isOpen1: false,
        isOpen2: false,
      };
    default:
      throw new Error("Unknown Modal Action!");
  }
}

const ModalStateContext = React.createContext<State>(initialState);
ModalStateContext.displayName = "ModalStateContext";
const ModalActionContext = React.createContext<
  React.Dispatch<Action> | undefined
>(undefined);
ModalActionContext.displayName = "ModalActionContext";

export const ModalProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = React.useReducer(modalReducer, initialState);
  return (
    <ModalStateContext.Provider value={state}>
      <ModalActionContext.Provider value={dispatch}>
        {children}
      </ModalActionContext.Provider>
    </ModalStateContext.Provider>
  );
};

export function useModalState() {
  const context = React.useContext(ModalStateContext);
  if (context === undefined) {
    throw new Error(`useModalState must be used within a ModalProvider`);
  }
  return context;
}

export function useModalAction() {
  const dispatch = React.useContext(ModalActionContext);
  if (dispatch === undefined) {
    throw new Error(`useModalAction must be used within a ModalProvider`);
  }
  return {
    openModal(view?: MODAL_VIEWS, payload?: unknown, view2?: MODAL_VIEWS) {
      if (!!view2) {
        dispatch({ type: "open2", view2, payload });
      } else {
        dispatch({ type: "open1", view, payload });
      }
    },
    closeModal() {
      dispatch({ type: "close" });
    },
    closeModal2() {
      dispatch({ type: "close2" });
    },
  };
}
