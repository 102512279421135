import { AvailabilityTypeModel } from "../../types";

export const LIMIT = 10;
export const SUPER_ADMIN = "super_admin";
export const STORE_OWNER = "store_owner";
export const STAFF = "staff";
export const TOKEN = "token";
export const PERMISSIONS = "permissions";
export const AUTH_CRED = "AUTH_CRED";
export const EMAIL_VERIFIED = "emailVerified";
export const CART_KEY = "pick-cart";
export const CHECKOUT = "pickbazar-checkout";
export const RESPONSIVE_WIDTH = 659 as number;
export const AUTH_TOKEN_KEY = "auth_token";
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
// export const ACCEPTED_FILE_TYPES =
//   'image/*,application/pdf,application/zip,application/vnd.rar,application/epub+zip,.psd';

export const ACCEPTED_FILE_TYPES = {
  "image/jpeg": [],
  "image/png": [],
  "application/pdf": [],
  "application/zip": [],
  "application/vnd.rar": [],
  "application/epub+zip": [],
  ".psd": [],
};

export const AvailabilityTypeItems: AvailabilityTypeModel[] = [
  {
    value: "IN_STOCK",
    title: "In stock",
  },
  {
    value: "OUT_OF_STOCK",
    title: "Out of stock",
  },
  {
    value: "SHIP_TO_STORE",
    title: "Ship to store",
  },
];

export const AvailabilityPickupAtLocationUnitTypeItems: AvailabilityTypeModel[] =
  [
    {
      value: "BUSINESS_DAY",
      title: "Business day",
    },
    {
      value: "HOUR",
      title: "Hour",
    },
  ];

export const PackageDimensionsUnitItems: AvailabilityTypeModel[] = [
  {
    title: "Inch",
    value: "inch",
  },
  {
    title: "cm",
    value: "cm",
  },
  {
    title: "Meter",
    value: "m",
  },
];

export const PackageWrightUnitItems: AvailabilityTypeModel[] = [
  {
    title: "Pound",
    value: "POUND",
  },
  {
    title: "Ounce",
    value: "OUNCE",
  },
  {
    title: "Kilogram",
    value: "KILOGRAM",
  },
  {
    title: "Gram",
    value: "GRAM",
  },
];

export const PageSize = 10;
export const ImageListInModalPageSize = 5;
export const VideoListInModalPageSize = 5;
export const ProductListingGridShowPageSize = 10;
export const ProductOfferGridShowPageSize = 10;
