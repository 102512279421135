import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import client from "service/client";

export function useFileBusinessStoriesList() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.business.getFileBusinessStoriesList,
    {
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessStoryFileDelete() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.business.deleteStoryFileDelete,
    {
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessStoryAddFile() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.business.addStoryFile, {
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}
