import Button from "components/ui/button";
import Card from "components/ui/cards/card";
import Description from "components/ui/description";
import Input from "components/ui/forms/input";
import { useModalAction } from "provider/modal.context";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCommunicationMessagesList } from "service/hook/communicationMessage.hook";
import { userStore } from "store";
import {
  CommunicationMessage,
  CommunicationMessagesListRequestModel,
  CommunicationMessagesListResponseModel,
} from "types/communicationMessage";
import { PageSize } from "utils/constants";
import CommunicationMessageList from "./CommunicationMessagesList";

export default function CommunicationMessagesFormView() {
  const { t } = useTranslation();
  const { businessItem } = userStore((state) => state);

  const [customerEmail, setCustomerEmail] = useState<string>("");
  const [sellerEmail, setSellerEmail] = useState<string>("");
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [communicationList, setCommunicationList] = useState<
    CommunicationMessage[]
  >([]);

  const { mutate: mutateCommunicationMessage, isLoading } =
    useCommunicationMessagesList();

  useEffect(() => {
    if (businessItem) getCommunicationMessages();
  }, [businessItem, page]);

  const getCommunicationMessages = () => {
    const input: CommunicationMessagesListRequestModel = {
      page: page,
      size: PageSize,
      customer_email: customerEmail,
      businessdetail_id: `${businessItem?.business_id}`,
      seller_email: sellerEmail,
    };

    mutateCommunicationMessage(input, {
      onSuccess: (successData: CommunicationMessagesListResponseModel) => {
        if (successData.final_result_code === "000") {
          setCommunicationList(successData.captured_data);
          setTotalCount(successData.total_count ?? 0);
        }
      },
    });
  };

  const onPagination = (pageNumber: number) => {
    setPage(pageNumber);
  };

  return (
    <>
      <div className="my-5 flex flex-wrap sm:my-8">
        <Description
          details={[t("form:form-description-customer-messages")]}
          className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
        />
        <Card className="w-full sm:w-8/12 md:w-2/3">
          <div className="flex gap-4">
            <Input
              label={t("common:text-customer-email")}
              name="customerEmail"
              value={customerEmail}
              onChange={(e) => {
                setCustomerEmail(e.target.value);
              }}
              variant="outline"
              className="mb-5 w-full"
            />
            <Input
              label={t("common:text-seller-email")}
              name="sellerEmail"
              value={sellerEmail}
              onChange={(e) => {
                setSellerEmail(e.target.value);
              }}
              variant="outline"
              className="mb-5 w-full"
            />
          </div>

          <div
            className="mb-4 text-start"
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <div
              className="mb-4 text-start"
              style={{
                display: "flex",
                justifyContent: "start",
              }}
            >
              <Button
                onClick={() => {
                  page === 1 ? getCommunicationMessages() : setPage(1);
                }}
                loading={isLoading}
                disabled={isLoading}
              >
                {"Search"}
              </Button>
            </div>
          </div>

          <CommunicationMessageList
            communicationList={communicationList}
            paginatorInfo={{
              total: totalCount,
              currentPage: page,
              pageSize: PageSize,
            }}
            onPagination={onPagination}
          />
        </Card>
      </div>
    </>
  );
}
