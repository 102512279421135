export const Routes = {
  landing: "/",
  dashboard: "/dashboard",
  login: "/login",
  logout: "/logout",
  register: "/register",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  adminMyShops: "/my-shops",
  profile: "/profile",
  verifyCoupons: "/coupons/verify",
  settings: "/settings",
  storeSettings: "/vendor/settings",
  storeKeepers: "/vendor/store_keepers",
  profileUpdate: "/profile-update",
  checkout: "/orders/checkout",
  verifyEmail: "/verify-email",
  PubSubscription: "/Subscription2",
  business: "/business",
  terms: "/terms",
  privacy: "/privacy",
  faq: "/faq",
  gdpr: "/gdpr",
  //stripe
  refresh: "/reauth",
  return: "/return",
  success: "/success",

  BusinessSetup: "/BusinessSetup",
  BusinessSetupDetail: "Detail",
  BusinessSetupBranch: "Branch",
  BusinessSetupTeamOrMember: "Team-Member",
  BusinessSetupSectors: "Sectors",
  BusinessSetupBankCard: "BankCard",
  BusinessSetupBankAccount: "BankAccount",
  BusinessSetupServiceType: "ServiceType",
  BusinessSetupBusinessDocuments: "BusinessDocument",
  BusinessSetupBusinessProfile: "BusinessProfile",
  BusinessSetupBusinessLogo: "BusinessLogo",
  BusinessSetupSubscription: "Subscription",

  BusinessInPerson: "/BusinessInPerson",
  BusinessInPersonServices: "InPersonServices",
  BusinessStoryFile: "BusinessStoryFile",

  ProductManagement: "/ProductManagement",
  ProductManagementPolicy: "ProductPolicy",
  ProductManagementImage: "ProductImage",
  ProductManagementVideo: "ProductVideo",
  ProductManagementInventory: "ProductInventory",
  ProductManagementInventoryAddOrDetails: "Details/",
  ProductManagementInventoryDetails: "Details/:skuCode",
  ProductManagementInventoryUpdateBase: "Details/Edit/",
  ProductManagementInventoryUpdate: "Details/Edit/:skuCode",
  ProductManagementOffer: "ProductOffer",
  ProductManagementGroup: "ProductGroup",
  ProductManagementOfferAddOrDetails: "Details/",
  ProductManagementOfferDetails: "Details/:offerId",
  ProductManagementOfferUpdateBase: "Details/Edit/",
  ProductManagementOfferUpdate: "Details/Edit/:offerId",
  ProductManagementCategory: "ProductCategory",
  ProductManagementInventoryGroup: "ProductInventoryGroup",
  ProductManagementInventoryGroupAddOrDetails: "Details/",
  ProductManagementInventoryGroupDetails: "Details/:skuGroupCode",
  ProductManagementInventoryGroupUpdateBase: "Details/Edit/",
  ProductManagementInventoryGroupUpdate: "Details/Edit/:skuGroupCode",

  AIAnalytics: "/ai",
  AIAnalyticsDescriptionGenerator: "descriptionGenerator",

  ProductListing: "/ProductListing",
  ProductListingItems: "list",
  ProductListingPublishByOffer: "PublishByOffer",
  ProductListingPublishByOfferDetails: "Details/",
  ProductListingPublishByOfferDetailsWithOfferId: "Details/:offerId",
  ProductListingPublishByProductGroup: "PublishByProductGroup",
  ProductListingPublishByProductGroupDetails: "Details/",
  ProductListingPublishByProductGroupDetailsWithOfferId:
    "Details/:skuGroupCode/",

  AICopilot: "/ai_copilot",
  AICopilotDescriptionGenerator: "ai_copilot",
  AICopilotAdContent: "ai_copilot",
  AICopilotSiteContent: "ai_copilot",
  CRM: "/CRM",
  CRMCustomerMessages: "/customer-messages",
  CRMCustomerMessageDetails: "/customer-message-details",

  user: {
    ...routesFactory("/users"),
  },
  type: {
    ...routesFactory("/groups"),
  },
  category: {
    ...routesFactory("/categories"),
  },
  attribute: {
    ...routesFactory("/attributes"),
  },
  attributeValue: {
    ...routesFactory("/attribute-values"),
  },
  tag: {
    ...routesFactory("/tags"),
  },
  reviews: {
    ...routesFactory("/reviews"),
  },
  abuseReviews: {
    ...routesFactory("/abusive_reports"),
  },
  abuseReviewsReport: {
    ...routesFactory("/abusive_reports/reject"),
  },
  author: {
    ...routesFactory("/authors"),
  },
  coupon: {
    ...routesFactory("/coupons"),
  },
  manufacturer: {
    ...routesFactory("/manufacturers"),
  },
  order: {
    ...routesFactory("/orders"),
  },
  orderStatus: {
    ...routesFactory("/order-status"),
  },
  orderCreate: {
    ...routesFactory("/orders/create"),
  },
  product: {
    ...routesFactory("/products"),
  },
  shop: {
    ...routesFactory("/shops"),
  },
  tax: {
    ...routesFactory("/taxes"),
  },
  shipping: {
    ...routesFactory("/shippings"),
  },
  withdraw: {
    ...routesFactory("/withdraws"),
  },
  staff: {
    ...routesFactory("/staffs"),
  },
  refund: {
    ...routesFactory("/refunds"),
  },
  question: {
    ...routesFactory("/questions"),
  },
  message: {
    ...routesFactory("/message"),
  },
  shopMessage: {
    ...routesFactory("/shop-message"),
  },
  conversations: {
    ...routesFactory("/message/conversations"),
  },
  storeNotice: {
    ...routesFactory("/store-notices"),
  },
  storeNoticeRead: {
    ...routesFactory("/store-notices/read"),
  },
};

function routesFactory(endpoint: string) {
  return {
    list: `${endpoint}`,
    create: `${endpoint}/create`,
    editWithoutLang: (slug: string, shop?: string) => {
      return shop
        ? `/${shop}${endpoint}/${slug}/edit`
        : `${endpoint}/${slug}/edit`;
    },
    edit: (slug: string, language: string, shop?: string) => {
      return shop
        ? `/${language}/${shop}${endpoint}/${slug}/edit`
        : `${language}${endpoint}/${slug}/edit`;
    },
    translate: (slug: string, language: string, shop?: string) => {
      return shop
        ? `/${language}/${shop}${endpoint}/${slug}/translate`
        : `${language}${endpoint}/${slug}/translate`;
    },
    details: (slug: string) => `${endpoint}/${slug}`,
  };
}
